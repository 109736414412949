import React, { useState, Fragment } from "react";
import { Box } from "@mui/material";
import VerticalMenu from "../../components/VerticalMenu";
import HorizontalMenu from "../../components/HorizontalMenu";
import Container from "@mui/material/Container";
import Helmet from "react-helmet";
import favicon from "../../images/favicon.ico";
import { codeFont, pageStyles, pageContent } from "../../styleConstants";
import PageTitle from "../../components/PageComponents/PageTitle";
import Paragraph from "../../components/PageComponents/Paragraph";
import ParagraphTitle from "../../components/PageComponents/ParagraphTitle";
import SupportButton from "../../components/SupportButton";
import CodeContainer from "../../components/CodeContainer";
import VersionSwitch from "../../components/VersionSwitch";

const pythonCode = `
# import os module to access environmental modules
import os

import requests

# pass in your API key
os.environ[‘apiKey’]=‘12345' //use your API key instead of 12345

# Setup the API Key from the 'apiKey' environment variable
GLOBHE_API_KEY = os.getenv('apiKey')

BASE_URL = "https://us-central1-globhe-app.cloudfunctions.net/api/v1"

#setup a session
session = requests.Session()

#authenticate session with username and password, pass in an empty string for the password
session.auth = (GLOBHE_API_KEY, "")

#make a get request to the Data API
res = session.get(BASE_URL)

# test response
print(res.status_code)

# print response body
print(res.text)
`;

const IndexPage = () => {
  const [index, setIndex] = useState(1);

  const handleIndexChange = (newIndex) => {
    setIndex(newIndex);
  };
  return (
    <main style={pageStyles}>
      <HorizontalMenu />
      <Box style={pageContent}>
        <VerticalMenu page={1} />

        <Container maxWidth="md" style={{ wordWrap: "break-word" }}>
          <PageTitle text="Authentication" />
          <VersionSwitch handleIndexChange={handleIndexChange} index={index} />

          <Paragraph
            text="The GLOBHE API uses Basic HTTP Authentication and requires that you
            have a valid API key. Once you sign up as a client, you will automatically 
            receive an API key that you can find in your profile. This key will not be automatically approved. 
            See below how you can activate your API key."
          />
          <Paragraph
            text={
              <Fragment>
                Authenticate by setting <code style={codeFont}>username</code>{" "}
                to your API key.
              </Fragment>
            }
          />
          <ParagraphTitle text="**Authentication Via Basic HTTP with Python" />
          <CodeContainer text={pythonCode} />
          <ParagraphTitle text="**Authentication Via cURL" />
          {index === 0 ? (
            <CodeContainer text="curl -u {apiKey}: https://us-central1-globhe-app.cloudfunctions.net/api/v1" />
          ) : (
            <CodeContainer text="curl -u {apiKey}: https://us-central1-globhe-app.cloudfunctions.net/api/v2" />
          )}
          <br />
          <br />
          <ParagraphTitle text="Activate your API key" />
          <Paragraph text="To activate your API key on either the production or the testing environment, please contact support and you will receive a reply in less than 24 hours." />
          <SupportButton />
        </Container>
      </Box>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GLOBHE&apos;s developers docs</title>
        <link rel="icon" href={favicon} />
      </Helmet>
    </main>
  );
};

export default IndexPage;
